<template>
  <div>
    <div
      id="filter-custom"
      class="page-container mb-2"
    >
      <h3
        class="header"
      >
        Bộ lọc
      </h3>
      <b-row>
        <!-- Người lao động -->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Doanh nghiệp <span class="required"></span></label>
            <v-select
              :reduce="label => label.id"
              label="name"
              :options="dataComboboxBusiness"
              :placeholder="'Doanh nghiệp'"
              @input="filterBussiness"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
    </div>
    <div
      id="users"
      class="page-container-table"
    >
      <button-all-header
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideAdd="true"
        :hideDelete="true"
        contentBtnAdd="Thêm thanh tra, kiểm tra"
        @clickDelete="deleteItems"
        @clickDowloadSample="() => {}"
        @clickExportExcel="() => {}"
        @importFile="() => {}"
        @clickAdd="$router.push('/an-toan-ve-sinh-lao-dong/thanh-tra/tao-moi')"
        @search="search($event)"
      />

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataList || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã người dùng -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ props.row.code }}</span>
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'dateTime'">
            <span>
              {{ props.row.dateTime | formatDateToDDMM }}
            </span>
          </span>

          <span v-else-if="props.column.field === 'description'">
            <span>
              {{ props.row.description === 'null' ? '' : props.row.description }}
            </span>
          </span>

          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'ActionFunction'">

            <span
              v-if="props.row.fileName"
              @click="downloadFilePdf(props.row.id, props.row.fileName)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Tải file'"
                icon="DownloadIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="showModalEditSupervise(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="text-body"
              />
            </span>
            <span
              class="ml-2"
              @click="deleteItem(props.row.id)"
            >
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body"
              />
            </span>
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="pageChange"
      />
      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
  BRow,
  BFormGroup,
  BCol,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Create from './pages/Create.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Search from '@/components/search/Search.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from './constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    MyPagination,
    FeatherIcon,
    ConfirmModal,
    ButtonAllHeader,
    Create,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'MÃ THANH TRA',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN ĐỢT THANH TRA',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY THANH TRA',
          field: 'dateTime',
          sortable: false,
        },
        {
          label: 'NỘI DUNG',
          field: 'description',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        bussinessId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalType: '',
      totalRecord: 0,
      userId: '',
      dataDetailInjury: {},
      injuryId: '',
      modalIdCreate: 'modalIdCreate',
      modalIdEdit: 'modalIdEdit',
      dataList: [],
      idModalSee: 'modal-see-info',
      idDetail: '',
      dataComboboxBusiness: [],
      // dataList: dataFake.data,
    }
  },
  created() {
    this.fetchData(this.urlQuery)
    this.fetchComboboxBusiness()
  },
  methods: {
    // Danh sách combobox doanh nghiệp
    async fetchComboboxBusiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_BUSSINESS)
      this.dataComboboxBusiness = data?.data
    },
    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_SUPERVISE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    showModalEditSupervise(id) {
      this.$router.push({ name: 'supervise-edit', params: { id } })
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.API_SUPERVISE, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    filterBussiness(val) {
      if (val != null) {
        this.urlQuery.bussinessId = val
        this.urlQuery.pageNumber = 1
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.bussinessId = ''
        this.urlQuery.pageNumber = 1
        this.fetchData(this.urlQuery)
      }
    },

    // tải file đính kèm.
    downloadFilePdf(id, fileName) {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_FILE_SUPERVISE, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.$root.$bvToast.toast('Không có file', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
