var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page-container mb-2",attrs:{"id":"filter-custom"}},[_c('h3',{staticClass:"header"},[_vm._v(" Bộ lọc ")]),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Doanh nghiệp "),_c('span',{staticClass:"required"})]),_c('v-select',{attrs:{"reduce":function (label) { return label.id; },"label":"name","options":_vm.dataComboboxBusiness,"placeholder":'Doanh nghiệp'},on:{"input":_vm.filterBussiness},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"page-container-table",attrs:{"id":"users"}},[_c('button-all-header',{attrs:{"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"arrayExcel":[],"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideAdd":true,"hideDelete":true,"contentBtnAdd":"Thêm thanh tra, kiểm tra"},on:{"clickDelete":_vm.deleteItems,"clickDowloadSample":function () {},"clickExportExcel":function () {},"importFile":function () {},"clickAdd":function($event){return _vm.$router.push('/an-toan-ve-sinh-lao-dong/thanh-tra/tao-moi')},"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"light-code",attrs:{"pill":""}},[_c('span',{staticClass:"text-name"},[_vm._v(_vm._s(props.row.code))])])],1):(props.column.field === 'dateTime')?_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.dateTime))+" ")])]):(props.column.field === 'description')?_c('span',[_c('span',[_vm._v(" "+_vm._s(props.row.description === 'null' ? '' : props.row.description)+" ")])]):(props.column.field === 'ActionFunction')?_c('span',[(props.row.fileName)?_c('span',{on:{"click":function($event){return _vm.downloadFilePdf(props.row.id, props.row.fileName)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Tải file'),expression:"'Tải file'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"DownloadIcon","size":"18"}})],1):_vm._e(),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.showModalEditSupervise(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EditIcon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }